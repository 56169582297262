<template>
  <div class="container">
    <div>
      <el-form :model="form"
               style="width:70vw;min-width:1000px;text-align:left"
               label-position="right"
               label-width="100px"
               :rules="rules"
               ref="ruleForm">
        <div style="display:flex">
          <el-form-item label="快递公司"
                        prop="company_name"
                        style="margin-right:120px">
            <el-select v-model="form.company_name"
                       placeholder="请选择"
                       @change="companyChange">
              <el-option v-for="item in companyList"
                         :key="item.co_id"
                         :label="item.company_name"
                         :value="item.company_name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板名称"
                        prop="shipping_name"
                        style="margin-right:120px">
            <el-input v-model="form.shipping_name"
                      placeholder="请输入模板名称，不超过20个字符"
                      maxlength="20"
                      style="width:340px"></el-input>
          </el-form-item>
        </div>

        <el-form-item label="是否包邮"
                      prop="freight_type">
          <el-radio v-model="form.freight_type"
                    :label="1">不包邮，自定义运费</el-radio>
          <el-radio v-model="form.freight_type"
                    :label="2">包邮</el-radio>
        </el-form-item>
        <div v-if="form.freight_type==1">
          <el-form-item label="运费配置"
                        prop="shipping_area">
            <div style="color:#666">配送范围</div>
            <el-radio v-model="form.shipping_area"
                      :label="1">全国</el-radio>
            <el-radio v-model="form.shipping_area"
                      :label="2">部分地区</el-radio>
          </el-form-item>
          <div v-if="form.shipping_area==1">
            <div style="color:#666;padding-left:100px;margin-bottom:20px">
              <span style="color: #ff514b;">*</span>
              默认运费
            </div>
            <div style="display:flex;align-items:center;padding-left:100px;">
              <el-input-number v-model="form.weight_snum"
                               controls-position="right"
                               :min="0"
                               :precision="1"
                               style="width:150px"></el-input-number>
              <span style="margin:0 5px;color:#666">kg以内</span>
              <el-input-number v-model="form.weight_sprice"
                               controls-position="right"
                               :min="0"
                               :precision="1"
                               style="width:150px"></el-input-number>
              <span style="margin:0 5px;color:#666">元，每增加</span>
              <el-input-number v-model="form.weight_xnum"
                               controls-position="right"
                               :min="0"
                               :precision="1"
                               style="width:150px"></el-input-number>
              <span style="margin:0 5px;color:#666">kg，增加运费</span>
              <el-input-number v-model="form.weight_xprice"
                               controls-position="right"
                               :min="0"
                               :precision="1"
                               style="width:150px"></el-input-number>
              <span style="margin-left:5px;color:#666">元</span>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div v-if="form.freight_type==1">
      <div style="padding-left:100px;text-align:left;margin:20px 0">
        <span style="margin-right:10px;color:#666">
          <span style="color: #ff514b;margin-right:5px">*</span>指定地区运费
        </span>
        <span style="font-size:12px;color:#ccc"
              v-if="form.shipping_area==1">不按“默认运费”计算的特殊地区运费价格</span>
      </div>
      <div style="padding:0 40px 0 100px;margin-bottom:20px">
        <div style="border: solid 1px #c9cbd6;">
          <el-table ref="multipleTable"
                    :data="freightData"
                    tooltip-effect="dark"
                    style="width: 100%;min-width:1000px;text-align:center"
                    :header-cell-style="{background:'#F5F5F5'}">
            <el-table-column label="配送区域"
                             width="330">
              <template slot-scope="scope">
                <div style="display:flex">
                  <div class="citybox"
                       v-if="scope.row.province_city_name">{{scope.row.province_city_name}}</div>
                  <el-button type="text"
                             @click="showarea(scope.$index,scope.row)">编辑</el-button>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="首重（kg）">
              <template slot-scope="scope">
                <el-input-number style="width:140px"
                                 v-model="scope.row.weight_snum"
                                 controls-position="right"
                                 :min="0"
                                 :precision="1"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="运费（元）">
              <template slot-scope="scope">
                <el-input-number style="width:140px"
                                 v-model="scope.row.weight_sprice"
                                 controls-position="right"
                                 :min="0"
                                 :precision="1"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="续重（kg）">
              <template slot-scope="scope">
                <el-input-number style="width:140px"
                                 v-model="scope.row.weight_xnum"
                                 controls-position="right"
                                 :min="0"
                                 :precision="1"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="续费（元）">
              <template slot-scope="scope">
                <el-input-number style="width:140px"
                                 v-model="scope.row.weight_xprice"
                                 controls-position="right"
                                 :min="0"
                                 :precision="1"></el-input-number>
              </template>
            </el-table-column>

            <el-table-column label="操作"
                             width="60px">
              <template slot-scope="scope">
                <el-button type="text"
                           @click="delFreight(scope.$index)">
                  <i class="el-icon-delete"
                     style="color:#666"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align:left;margin-top:20px">
          <el-button type="primary"
                     style="width:162px;"
                     @click="addFreight">添加指定地区运费</el-button>
        </div>
      </div>
    </div>

    <!-- 选择区域弹窗 -->
    <el-dialog title="选择配送区域"
               :visible.sync="dialogVisible"
               width="728px"
               style="text-align:left"
               :close-on-click-modal="false">
      <div class="areabox">
        <div class="areaitem"
             v-for="(area,index) in areaData"
             :key="index"
             ref="checkedbg">
          <div class="checkpro">
            <el-checkbox v-model="area.isChecked"
                         @change="(val)=>{return changebg(val,index) }">{{area.area_name}}</el-checkbox>
          </div>
          <div class="provinceitem">
            <div v-for="(pro,ind) in area.province"
                 :key="ind">
              <span @click="choosePro(pro,area,index)"
                    :style="{color:pro.isChecked?'#1890ff':'#333'}">{{pro.name}}</span>

              <span v-for="(dis,i) in pro.city"
                    :key="i"
                    :style="{color:dis.isChecked?'#1890ff':'#999'}"
                    @click="chooseCity(dis,pro,area,index,ind)">{{dis.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button plain
                   @click="dialogVisible=false"
                   style="width:100px;margin-top:20px">取消</el-button>
        <el-button type="primary"
                   @click="chooseAdd"
                   style="width:100px;margin-top:20px">确定</el-button>
      </span>
    </el-dialog>

    <div class="btnbox">
      <el-button plain
                 style="width:120px;margin-right:20px"
                 @click="back">返回</el-button>
      <el-button type="primary"
                 style="width:120px"
                 @click="submitForm('ruleForm')">保存</el-button>
    </div>
  </div>
</template>
<script>

export default {
  name: "ft",
  data() {
    return {
      area_id: "",
      form: {
        shipping_id: "",
        shipping_name: "",
        co_id: "", //快递公司id
        company_name: "", //快递公司名字
        //1自定义运费，2包邮
        freight_type: 1,
        //1全国，2部分地区
        shipping_area: 1,
        //首重
        weight_snum: "",
        //首重运费
        weight_sprice: "",
        //续重
        weight_xnum: "",
        //续重运费
        weight_xprice: "",
        //指定区域运费
        shipping_cities: []
      },

      freeLimit: false,
      dialogVisible: false,
      provinceList: [],
      queryCityList: [],
      province_name: "",
      city_name: "",
      freightData: [],
      areaData: [],
      checked_row: "",
      checked_index: "",
      rules: {
        shipping_name: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
          { max: 20, message: "不超过20个字符", trigger: "blur" }
        ],
        company_name: [
          { required: true, message: "请选择快递公司", trigger: "blur" }
        ],
        delivery_city_id: [
          {
            required: true,
            message: "请选择发货地址",
            trigger: ["blur", "change"]
          }
        ],
        freight_type: [
          {
            required: true,
            message: "请选择是否包邮",
            trigger: ["blur", "change"]
          }
        ],
        weight_snum: [
          { required: true, message: "请输入首重", trigger: "blur" }
        ],
        weight_sprice: [
          { required: true, message: "请输入首重运费", trigger: "blur" }
        ],
        weight_xnum: [
          { required: true, message: "请输入续重", trigger: "blur" }
        ],
        weight_xprice: [
          { required: true, message: "请输入续重运费", trigger: "blur" }
        ]
      },
      companyList: []
    };
  },
  methods: {
    //获取大区-省份-城市-区县
    getAreaList() {
      this.axios.post('/store/platform/getAreaProvinceCity')
        .then(res => {
          let areaData = res.data.list;
          areaData.forEach(item => {
            item.isChecked = false;
            item.province.forEach(pro => {
              pro.isChecked = false;
              pro.city.forEach(city => {
                city.isChecked = false;
              });
            });
          });
          this.areaData = areaData;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //编辑区域弹窗
    showarea(index, row) {
      this.checked_index = index;
      let pro_id = [];
      let city_id = [];
      pro_id = this.freightData[index].provinces_id.split(",");
      city_id = this.freightData[index].cities_id.split(",");

      this.checked_row = row;

      this.areaData.forEach(item => {
        item.isChecked = false;
        let a = 0;
        item.province.forEach(pro => {
          pro.name = pro.name.replace(/\(.*?\)/g, "");
          pro.isChecked = false;
          if (pro_id.includes(pro.province_id + "")) {
            pro.isChecked = true;
            a = 1;
          }
          pro.city.forEach(city => {
            city.isChecked = false;
            if (city_id.includes(city.city_id + "")) {
              city.isChecked = true;
            }
          });
        });
        if (a) {
          item.isChecked = true;
        }
      });
      this.dialogVisible = true;
      this.$refs.checkedbg.forEach(i => {
        i.style.background = "white";
      });
    },
    //选择大区
    changebg(val, index) {
      this.areaData[index].isChecked = val;
      this.area_id = this.areaData[index].area_id;
      if (val) {
        this.$refs.checkedbg[index].style.background = "#e6f7ff";
        this.areaData[index].province.forEach(i => {
          i.isChecked = true;
          i.city.forEach(j => {
            j.isChecked = true;
          });
        });
      } else {
        this.$refs.checkedbg[index].style.background = "white";
        this.areaData[index].province.forEach(i => {
          i.isChecked = false;
          i.city.forEach(j => {
            j.isChecked = false;
          });
        });
      }
    },
    //选择省
    choosePro(pro, area, index) {
      pro.isChecked = !pro.isChecked;
      area.isChecked = false;
      this.$refs.checkedbg[index].style.background = "white";
      pro.city.forEach(ele => {
        ele.isChecked = pro.isChecked;
      });
      this.area_id = area.area_id;
      this.areaData[index].province.forEach(i => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          area.isChecked = true;
          this.$refs.checkedbg[index].style.background = "#e6f7ff";
        }
      });
    },
    //选择市
    chooseCity(dis, pro, area, index, ind) {
      pro.isChecked = false;
      area.isChecked = false;
      this.$refs.checkedbg[index].style.background = "white";
      dis.isChecked = !dis.isChecked;
      this.area_id = area.area_id;
      this.areaData[index].province[ind].city.forEach(i => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          pro.isChecked = true;
        }
      });
      this.areaData[index].province.forEach(i => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          area.isChecked = true;
          this.$refs.checkedbg[index].style.background = "#e6f7ff";
        }
      });
    },
    //选择的地区
    chooseAdd() {
      let proData = [];
      let cityData = [];
      let index = this.checked_index;
      this.areaData.map(area => {
        if (area.isChecked) {
          area.province.map(pro => {
            if (pro.isChecked) {
              let a = 0;
              let city_name = [];
              pro.city.map(city => {
                if (!city.isChecked) {
                  a = 1;
                }
              });
              if (a) {
                pro.city.map(city => {
                  if (city.isChecked) {
                    city_name.push(city.name);
                  }
                });
                pro.name = pro.name + "(" + city_name.toString() + ")";
              }
              proData.push(pro);
            }
            pro.city.map(city => {
              if (city.isChecked) {
                cityData.push(city);
              }
            });
          });
        }
      });
      let pro_id = [];
      let city_id = [];
      let pros = [];
      pros = proData.map(item => {
        return item.name;
      });
      pro_id = proData.map(item => {
        return item.province_id;
      });
      city_id = cityData.map(item => {
        if (item.isChecked) {
          return item.city_id;
        }
      });

      this.freightData[index].province_city_name = pros.toString();
      this.$set(this.freightData, index, this.freightData[index]);
      this.freightData[index].provinces_id = pro_id.toString();
      this.freightData[index].cities_id = city_id.toString();
      this.dialogVisible = false;
    },

    //设置运费增加行
    addFreight() {
      let data = {
        provinces_id: "",
        cities_id: "",
        weight_snum: "",
        weight_sprice: "",
        weight_xnum: "",
        weight_xprice: ""
      };
      this.freightData.push(data);
    },
    //删除运费设置
    delFreight(index) {
      this.freightData.splice(index, 1);
    },
    back() {
      history.back();
    },
    //编辑获取详情
    getTemplateDetail(shipping_id) {
      this.axios.post('/store/Shopshipping/getShopShippingInfo', { shipping_id }).then(res => {
        let detail = res.data.info;
        this.form.shipping_id = detail.shipping_id;
        this.form.shipping_name = detail.shipping_name;
        this.form.freight_type = detail.freight_type;
        this.form.shipping_area = detail.shipping_area;
        this.form.weight_snum = detail.weight_snum;
        this.form.weight_sprice = detail.weight_sprice;
        this.form.weight_xnum = detail.weight_xnum;
        this.form.weight_xprice = detail.weight_xprice;
        this.form.co_id = detail.co_id;
        this.form.company_name = detail.company_name;
        if (detail.shipping_cities.length) {
          this.freightData = detail.shipping_cities;
        }
      });
    },
    getExpressCompanyList() {
      this.axios
        .post("/store/Shoporder/getExpressCompanyList")
        .then(res => {
          this.companyList = res.data.list;
        });
    },
    //改变快递公司
    companyChange(value) {
      let obj = this.companyList.find(ele => {
        return ele.company_name == value;
      });
      this.form.shipping_name = obj.company_name;
      this.form.co_id = obj.co_id;
      // if (!this.form.shipping_name && !this.form.shipping_id) {
      // }
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = this.form;
          //如果不包邮并且全国配送 验证默认运费是否设置
          if (this.form.shipping_area == 1 && this.form.freight_type == 1) {
            if (
              !this.form.weight_snum ||
              !this.form.weight_sprice ||
              !this.form.weight_xnum ||
              !this.form.weight_xprice
            ) {
              this.$message.error("请设置默认运费");
              return false;
            }
          }
          //如果添加了指定配送区域 验证是否填了
          if (this.freightData.length) {
            for (let i = 0; i < this.freightData.length; i++) {
              if (!this.freightData[i].province_city_name) {
                this.$message.error("请选择需要单独设置运费的城市");
                return false;
              }
            }
          }

          if (
            this.form.is_area_market_restriction &&
            this.form.is_set_cities_free
          ) {
            let freightData_pro = [];
            let freightData_city = [];

            for (let i = 0; i < this.freightData.length; i++) {
              freightData_pro = freightData_pro.concat(
                this.freightData[i].provinces_id.split(",")
              );
              freightData_city = freightData_city.concat(
                this.freightData[i].cities_id.split(",")
              );
            }
          }
          data.shipping_cities = this.freightData;
          this.axios.post('/store/Shopshipping/saveShopShipping', data).then(res => {
            if (res.code == 0) {
              this.$message.success("操作成功");
              this.back();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
  created() {
    this.getAreaList();
    this.getExpressCompanyList();
    if (this.$route.query.shipping_id) {
      this.getTemplateDetail(this.$route.query.shipping_id);
    }
  }
};
</script>
<style lang="scss" scoped>
.btnbox {
  height: 120px;
  background: #fff;
  line-height: 120px;
  text-align: right;
  padding-right: 40px;
}
.areabox {
  width: 668px;
  height: 446px;
  border: solid 1px #c9cbd6;
  margin: 0 auto;
  margin-top: 10px;
  overflow-y: scroll;
  .areaitem {
    display: flex;
    border-bottom: 2px solid #e8e9f0;
    .checkpro {
      width: 74px;
      border-right: 2px solid #e8e9f0;
      text-align: center;
      margin-right: 10px;
      padding-top: 1.8%;
    }
    .provinceitem {
      div {
        margin: 10px 0;
        width: 550px;
      }
      span {
        margin-right: 15px;
        display: inline-block;
        margin-bottom: 5px;
        color: #999;
        cursor: pointer;
      }
      span:first-child {
        color: #333;
        padding-right: 10px;
        border-right: 2px solid #e8e9f0;
        margin-bottom: 5px;
      }
    }
  }
}
.citybox {
  width: 270px;
  margin-right: 10px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 5px;
  background: #fff;
}
</style>
